import React from "react";
import { PrintOutlined, HelpOutlined, SettingsOutlined, Archive } from "@mui/icons-material";
import { Roles, RolesKeys } from "./components/Auth";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { MediaLibProvider } from "./toolympus/components/medialib";
import { CMS } from "./components/CMS";
import { Documentation } from "./components/Documentation";
import { SiteSettingsPage } from "./components/Settings";
import { ContestControlPage } from "./components/ContestControl";
import { CrmContactList } from "./components/CRM";
import { TeamProfilePage } from "./components/Teams";
import { AdminArbitratorsList, ArbitratorHomePage, ArbitratorProfilePage } from "./components/Arbitrators";

import { BiHomeAlt2, BiGroup, BiCog, BiAt, BiMeteor, BiUser, BiBookmarkAlt, BiNetworkChart, BiGhost, BiSolidDashboard, BiFile } from "react-icons/bi";
import { BaseBiIconProps } from "./components/Common/Icons";
import { AdminTeamListPage } from "./components/Teams/AdminTeamList";
import { AdminLegacyInfoList } from "./components/LegacyInfo";
import { TeamLegacyPage } from "./components/Teams/TeamLegacy";
import { isDevMode } from "./toolympus/components/primitives";
import { GridPage } from "./components/Rounds/GridPage";
import { AdminBailiffsList, BailiffProfilePage } from "./components/Bailiffs";
import { TextPage } from "./components/Common";
import { BailiffStubRoundPage } from "./components/Rounds/RoundPages/BailiffStubRoundPage";
import { ArbitratorRoundPage, BailiffRoundPage, TeamRoundPage, AdminRoundsInfoListPage, ParticipantRoundsList } from "./components/Rounds/RoundPages";
import { TeamDocumentsPage } from "./components/Teams/TeamProfile/TeamDocumentsPage";
import { CourtRoomPage } from "./components/Rounds/RoundPages/CourtRoom/CourtRoomPage";
import { SnapshotsManagement } from "./components/ContestControl/SnapshotsManagement";

const isTeam = (roles: string[] | undefined) => (roles || []).includes(RolesKeys.team);
const isArbitrator = (roles: string[] | undefined) => (roles || []).includes(RolesKeys.arbitrator);
const isBailiff = (roles: string[] | undefined) => (roles || []).includes(RolesKeys.bailiff);
const isAdmin = (roles: string[] | undefined) => (roles || []).includes(RolesKeys.admin);

const InnerRoutes: MenuRoute[] = [
    {
      path: "/team/home",
      title: "Home",
      icon: <BiHomeAlt2 {...BaseBiIconProps} />,
      component: TextPage,
      componentProps: { slug: "team-home-page "},
      resolveHidden: isTeam,
    },
    {
      path: "/team/profile",
      title: "Team Profile",
      icon: <BiGroup {...BaseBiIconProps} />,
      component: TeamProfilePage,
      resolveHidden: isTeam,
    },
    {
      path: "/team/documents",
      title: "Team Documents",
      icon: <BiFile {...BaseBiIconProps} />,
      component: TeamDocumentsPage,
      resolveHidden: isTeam,
    },
    {
      path: "/team/legacy",
      title: "Legacy",
      icon: <BiBookmarkAlt {...BaseBiIconProps} />,
      component: TeamLegacyPage,
      resolveHidden: x => isTeam(x) && isDevMode(),
    },


    {
      path: "/judge/home",
      title: "Home",
      icon: <BiHomeAlt2 {...BaseBiIconProps} />,
      component: ArbitratorHomePage,
      resolveHidden: isArbitrator,
    },
    {
      path: "/judge/profile",
      title: "Profile",
      icon: <BiUser {...BaseBiIconProps} />,
      component: ArbitratorProfilePage,
      resolveHidden: isArbitrator,
    },


    {
      path: "/bailiff/home",
      title: "Home",
      icon: <BiHomeAlt2 {...BaseBiIconProps} />,
      component: TextPage,
      componentProps: { slug: "bailiff-home-page "},
      resolveHidden: isBailiff,
    },
    {
      path: "/bailiff/profile",
      title: "Profile",
      icon: <BiUser {...BaseBiIconProps} />,
      component: BailiffProfilePage,
      resolveHidden: isBailiff,
    },


    {
      path: "/contest",
      title: "Contest",
      icon: <BiMeteor {...BaseBiIconProps} />,
      component: ContestControlPage,
      resolveHidden: isAdmin,
    },

    {
      path: "/admin/teams",
      title: "Teams",
      icon: <BiGroup {...BaseBiIconProps} />,
      component: AdminTeamListPage,
      resolveHidden: isAdmin,
    },
    {
      path: "/admin/judges",
      title: "Judges",
      icon: <BiUser {...BaseBiIconProps} />,
      component: AdminArbitratorsList,
      resolveHidden: isAdmin,
    },
    {
      path: "/admin/bailiffs",
      title: "Bailiffs",
      icon: <BiGhost {...BaseBiIconProps} />,
      component: AdminBailiffsList,
      resolveHidden: isAdmin,
    },

    {
      path: "/admin/rounds",
      title: "Rounds",
      icon: <BiNetworkChart {...BaseBiIconProps} />,
      component: GridPage,
      resolveHidden: isAdmin,
    },
    {
      path: "/admin/round-status",
      title: "Rounds status",
      icon: <BiSolidDashboard {...BaseBiIconProps} />,
      component: AdminRoundsInfoListPage,
      resolveHidden: isAdmin,
    },

    {
      path: "/legacy",
      title: "Legacy",
      icon: <BiBookmarkAlt {...BaseBiIconProps} />,
      component: AdminLegacyInfoList,
      resolveHidden: isAdmin,
    },

    {
      path: "/contact",
      title: "Contacts",
      icon: <BiAt {...BaseBiIconProps} />,
      component: CrmContactList,
      resolveHidden: isAdmin,
    },


    {
      path: "/round-stub",
      title: "Contacts",
      hidden: true,
      icon: <BiAt {...BaseBiIconProps} />,
      component: BailiffStubRoundPage,
      resolveHidden: isAdmin,
    },
    {
      path: "/judge/round/:roundid",
      title: "Round",
      hidden: true,
      component: ArbitratorRoundPage,
      resolveHidden: isArbitrator,
    },
    {
      path: "/judge/round",
      title: "Rounds",
      component: ParticipantRoundsList,
      icon: <BiNetworkChart {...BaseBiIconProps} />,
      resolveHidden: isArbitrator,
      alsoActivateForPath: path => path.startsWith("/judge/round/"),
    },

    {
      path: "/team/round/:roundid",
      title: "Round",
      hidden: true,
      component: TeamRoundPage,
      resolveHidden: isTeam,
    },
    {
      path: "/team/round",
      title: "Rounds",
      component: ParticipantRoundsList,
      icon: <BiNetworkChart {...BaseBiIconProps} />,
      resolveHidden: isTeam,
      alsoActivateForPath: path => path.startsWith("/team/round/"),
    },

    {
      path: "/bailiff/round/:roundid",
      title: "Round",
      hidden: true,
      component: BailiffRoundPage,
      resolveHidden: isBailiff,
    },
    {
      path: "/bailiff/round",
      title: "Rounds",
      component: ParticipantRoundsList,
      icon: <BiNetworkChart {...BaseBiIconProps} />,
      resolveHidden: isBailiff,
      alsoActivateForPath: path => path.startsWith("/bailiff/round/"),
    },


    {
      path: "/court-room",
      title: "Court Room",
      hidden: true,
      component: CourtRoomPage,
    },



    { 
        path: '/system',
        title: 'System',
        icon: <BiCog {...BaseBiIconProps} />,
        resolveHidden: isAdmin,
        component: () => (
          <MediaLibProvider apiPath="/api/media" spaceId="1" urlPrefix="/media">
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                notificationTasks={{ apiPath: "/api/notifications/task" }}
                users={{ userRoles: Roles, allowPaswordReset: true, allowPaswordChange: true, allowCreateUsers: true }}
                dictionaries={{ apiPath: "/api/settings/dictionaries" }}
                messages={{}}
                queryConsole={{}}
                robud={{}}
                scoringSheets={{ apiPath: "/api/scoring/sheet" }}
                extraItems={[
                  { key: "site-settings", label: "Site Settings", icon: <SettingsOutlined />, items: [
                    { key: "site-settings", label: "", component: SiteSettingsPage }
                  ] },
                  { key: "cms", label: "CMS", icon: <PrintOutlined />, items: [
                      { key: "cms", label: "", component: CMS }
                  ] },
                  {
                    key: "snapshots",
                    label: "Snapshots",
                    icon: <Archive />,
                    items: [{ key: "snapshots", label: "", component: SnapshotsManagement }]
                  },
                  { key: "documentation", label: "Documentation", icon: <HelpOutlined />, items: [
                    { key: "documentation", label: "", component: Documentation }
                  ] },
              ]}
            />
          </MediaLibProvider>),
    },
];

export default InnerRoutes;